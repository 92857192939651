/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS301P
 * 화면 설명: 발행 영수증번호 발행번호 키워드 입력 검색 팝업
 */
 <template>
 <ur-page-container class="msp" :title="pTitle" :show-title="true" :topButton="true" type="subpage" > <!-- type="popup" -->
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container alignV="start" componentid="" direction="column">
        <div class="ns-certify-sed mt30 "> <!-- <div class="ns-certify-sed error"> 에러시 error 추가 error-->
          <!-- 영수증번호/발행번호 -->
          <mo-validate-watcher ref="vWatcher" class="ns-certify-sed mt30">
            <div class="ns-certify-sed-code row-text">              
              <mo-text-field :rules="validateRule" v-model="searchKeyword" type="number" mask="####################" class="full" underline :placeholder="placeHolderText"  @keyup.enter="fn_Search"/>
            </div>
          </mo-validate-watcher>

          <div class="ns-certify-sed-txt">
            <div class="txt-error">영수증번호 20자리를 입력해 주세요.</div>
          </div>
        </div>
      </ur-box-container>
    </ur-box-container>
    <!-- Content 영역 end -->
    <template>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear">초기화</mo-button>
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Search">조회</mo-button>
          </div>
        </ur-box-container>
      </template>
  </ur-page-container>

</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import Msg from '@/systems/webkit/msg/msg'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS301P', 
  screenId: 'MSPPS301P', 
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    
    pTitle         : {type:String, default:'상세조건'},
    pParntSrnId    : {type:String, default:''}, // 부모 화면 ID
    pSearchKeyword : {type:String, default:''}, // Default set Keyword Value
    pInptScVal     : {type:String, default:''} //입력구분값'V': 영수증번호 , 'P': 발행번호
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  //팝업 형태 속성
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      validateRule: [
          v => this.isChkNo || this.validateMsg
        ],
      validateMsg: '',
      isChkNo: false,  //입력한 값 체크
      parntSrnId: null, // 부모 화면 ID
      searchKeyword: null, // Default set Keyword Value
      inptScVal: 'V', //입력구분값'V': 영수증번호 , 'P': 발행번호
      placeHolderText:'', // placeholder 
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    
    //받은 파라미터 셋팅
    this.parntSrnId     = this.pParntSrnId? this.pParntSrnId.trim() : '' // 부모 화면 ID
    this.searchKeyword  = this.pSearchKeyword? this.pSearchKeyword.trim() : '' // Default set Keyword Value
    this.inptScVal      = this.pInptScVal? this.pInptScVal.trim() : ''; //입력구분값'V': 영수증번호 , 'P': 발행번호

    if(this.inptScVal == 'V'){
        this.placeHolderText = '영수증번호 20자리 입력'
        this.validateMsg = '영수증번호 20자리를 입력하세요'
    }else if(this.inptScVal == 'P'){
        this.placeHolderText = '발행번호 20자리 입력'
        this.validateMsg = '발행번호 20자리를 입력하세요'
    }

    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    searchKeyword() {
      
      if (this.searchKeyword.length === 0 || this.searchKeyword.length === 20) {
        this.isChkNo = true
      } else {
        this.isChkNo = false
      }
      return this.isChkNo
    }
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    
    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      //모달팝업 자기 자신 1개 보다 크면 다른 모달이 있는걸로 간주
      if(PSCommUtil.fn_GetActiveModalCnt() > 1) return
      this.fn_Clear() 
      this.$emit('onPopupReset',null)
    }, 

    /******************************************************************************
    * Function명  : fn_Search
    * 설명        : 조회 액션
    ******************************************************************************/
    fn_Search () {
      //this.$refs.vWatcher.clearValidation()
      
      this.searchKeyword = this.searchKeyword? this.searchKeyword.trim() : ''
      this.$refs.vWatcher.validate()
      if (!this.isChkNo) return

      let rtnData = {
        parntSrnId : this.parntSrnId , // 부모 화면 ID
        inptScVal : this.inptScVal , // 입력구분값'V': 영수증번호 , 'P': 발행번호
        searchKeyword : this.searchKeyword // 입력된 키워드 값
      }

      this.$emit('onPopupSearch',rtnData)
    },// fn_Search

    /******************************************************************************
    * Function명  : fn_Clear
    * 설명        : 초기화
    ******************************************************************************/
    fn_Clear () {
      this.searchKeyword = ''
    },// 마지막 메서드 함수 , 없음 주의



    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>